import React from "react"
import { Provider } from "react-redux"
import store from "./src/store"

const withState = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}


export default withState;