import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  
  palette: {
    mode: 'dark',
    primary: {
      main: '#8ab4f8',
    },
    secondary: {
      main: '#fcd56c',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    
  }
});


export default theme;