import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from "ethers";
import { trackWhitelistSubmitted } from '../firebase/analytics';
import { AppThunk } from '../store';
import { getContract } from '../web3/contract';


interface WhitelistState {
  loading: boolean;
  added: boolean;
  error: string | null;
}

const initialState: WhitelistState = {
  loading: false,
  added: false,
  error: null,
}



const service = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): WhitelistState {
      return { ...state, loading: action.payload };
    },
    setAdded(state): WhitelistState {
      return { ...state, added: true };
    },
    setError(state, action: PayloadAction<string | null>): WhitelistState {
      return { ...state, error: action.payload };
    },
  }
});


const submitToWhitelist = (address: string, email: string): AppThunk => async (dispatch, getState) => {
  try {
    const valid = ethers.utils.isAddress(address);
    if (!valid) {
      dispatch(service.actions.setError('Invalid address'));
      return;
    }

    dispatch(service.actions.setError(null));
    dispatch(service.actions.setLoading(true));

    const url = `${process.env.GATSBY_API_BASE}/whitelist/submit`;
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address,
        email,
      })
    });
    const result = await resp.json();
    if (result.success) {
      dispatch(service.actions.setAdded());
      trackWhitelistSubmitted();
    }
    else {
      dispatch(service.actions.setError(result.message));
    }
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}

export const getMerkleProof = async (address: string) => {
  try {

    const url = `${process.env.GATSBY_API_BASE}/whitelist/proof`;
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        address,
      })
    });
    const result = await resp.json();
    if (result.success) {
      return result.proof;
    }
  }
  catch (e) {
    console.error(e);
  }
  return null;
}



export const actions = {
  submitToWhitelist,
}

export default service.reducer;