import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from "ethers";


import { getContract } from '../web3/contract';
import { AppThunk } from '../store';
import BigNumber from 'bignumber.js';
import { connectWallet, getWallet, Wallet } from './wallet';
import { getMerkleProof } from './whitelist';
import { trackMintPendingConfirm, trackMintToken, trackMintWhitelistToken, trackWhitelistMintPendingConfirm } from '../firebase/analytics';

interface MintState {
  mintPending: boolean;
  transactionHash?: string;
  pendingWalletConfirm: boolean;
  error: string | null;
}

const initialState: MintState = {
  mintPending: false,
  pendingWalletConfirm: false,
  error: null,
}

const service = createSlice({
  name: 'mint',
  initialState,
  reducers: {
    setPendingWalletConfirm(state, action: PayloadAction<boolean>): MintState {
      return { ...state, pendingWalletConfirm: action.payload };
    },
    setError(state, action: PayloadAction<string | null>): MintState {
      return { ...state, error: action.payload };
    },
    setMintPending(state, action: PayloadAction<{ mintPending: boolean, transactionHash: string }>): MintState {
      return {
        ...state,
        ...action.payload,
      };
    },
  }
});




interface MintTokensArgs {
  count: number;
  price: BigNumber;
  onMintStarted: () => void;
}

const parseEtherError = (error: any) => {
  const metamaskError = 'MetaMask Tx Signature: ';

  const ethersErrorCodes: any = {
    'INSUFFICIENT_FUNDS': 'Insufficient funds on account',
  }

  const matches = error.toString().match(/"message":"execution reverted: (.*?)\"/i);
  if (matches) {
    return matches[ 1 ];
  }
  else if (error.message && error.message.includes(metamaskError)) {
    return error.message.replace('MetaMask Tx Signature: ', '');
  }
  else if (error.code && ethersErrorCodes[ error.code ]) {
    return ethersErrorCodes[ error.code ];
  }
  return 'Error occurred, please try again.';
}

const startMinting = ({ count, price, onMintStarted }: MintTokensArgs): AppThunk => async (dispatch, getState) => {
  try {
    let wallet = getWallet();
    if (!wallet) {
      return;
    }

    const contract = await getContract();


    const signer = wallet.provider.getSigner();
    const address = await signer.getAddress();
    const contractWithSigner = contract.connect(signer);

    dispatch(service.actions.setPendingWalletConfirm(true));
    dispatch(service.actions.setError(null));

    let transaction: any;

    if (process.env.GATSBY_SALE_MODE === 'presale') {
      const proof = await getMerkleProof(address);
      if (!Array.isArray(proof)) {
        dispatch(service.actions.setError('This address is not whitielisted'));
        dispatch(service.actions.setPendingWalletConfirm(false));
        return;
      }
      // const isWhitelisted = await contract.isWhitelisted(address, proof);
      // console.log('isWhitelisted', isWhitelisted);
      trackWhitelistMintPendingConfirm(count);
      transaction = await contractWithSigner.whitelistMintTo(address, count, proof, {
        value: ethers.utils.parseEther(price.toString())
      });
      trackMintWhitelistToken(count);
    }
    else {
      trackMintPendingConfirm(count);
      transaction = await contractWithSigner.mintTo(address, count, {
        value: ethers.utils.parseEther(price.toString())
      });
      trackMintToken(count);
    }
    console.log('transaction', transaction);

    dispatch(service.actions.setPendingWalletConfirm(false));
    dispatch(service.actions.setMintPending({
      mintPending: true,
      transactionHash: transaction.hash,
    }));
    onMintStarted();
    await transaction.wait();
    dispatch(service.actions.setMintPending({
      mintPending: false,
      transactionHash: transaction.hash,
    }));
  }
  catch (e: any) {
    dispatch(service.actions.setPendingWalletConfirm(false));
    dispatch(service.actions.setError(parseEtherError(e)));
    console.error('error.....', e);
  }
}

const mintTokens = ({ count, price, onMintStarted }: MintTokensArgs): AppThunk => async (dispatch, getState) => {
  try {

    let wallet = getWallet();

    if (!wallet) {
      dispatch(connectWallet({
        onConnected: () => {
          dispatch(startMinting({ count, price, onMintStarted }));
        }
      }))
    }
    else {
      dispatch(startMinting({ count, price, onMintStarted }));
    }
  }
  catch (e) {
    dispatch(service.actions.setPendingWalletConfirm(false));
    console.error(e);
  }
}



export const actions = {
  connectWallet,
  mintTokens,
}

export default service.reducer;