import { ethers } from "ethers";

import abi from './contractAbi';
import random from 'lodash/random';


export const getContract = async () => {

  const providers = [
    new ethers.providers.InfuraProvider(process.env.GATSBY_ETH_NETWORK, process.env.GATSBY_INFURA_ID),
    new ethers.providers.AlchemyProvider(process.env.GATSBY_ETH_NETWORK, process.env.GATSBY_ALCHEMY_KEY),
  ];

  const randomIndex = random(0, providers.length - 1);

  const provider = providers[ randomIndex ];
  const contract = new ethers.Contract(process.env.GATSBY_CONTRACT_ADDRESS ?? '', abi, provider);
  
  return contract;
}