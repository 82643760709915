import 'unfetch/polyfill';

import withState from "./withState"
import { trackPageView } from './src/firebase/analytics';

export const wrapRootElement = withState


export const onRouteUpdate = ({ location, prevLocation }) => {
  setTimeout(() => {
    // console.log('pageView', prevLocation?.pathname, location.pathname);
    trackPageView();
  }, 50);
};