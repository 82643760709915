import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics } from "firebase/analytics";



interface FirebaseInstance {
  app: FirebaseApp;
  analytics: Analytics;
}


let firebase: FirebaseInstance;

export const getFirebase = (): FirebaseInstance => {
  if (firebase) {
    return firebase;
  }

  const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);

  firebase = {
    app,
    analytics: getAnalytics(app)
  }

  return firebase;
}