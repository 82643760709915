import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from "ethers";
import { AppThunk } from '../store';
import { getContract } from '../web3/contract';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { trackWalletConnected, trackWalletDisconnected } from '../firebase/analytics';

interface WalletState {
  loading: boolean;
  account: string | null
  ethNetwork: string;
}

const initialState: WalletState = {
  account: null,
  loading: true,
  ethNetwork: process.env.GATSBY_ETH_NETWORK_ID as string,
}



const service = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setAccount(state, action: PayloadAction<string | null>): WalletState {
      return { ...state, account: action.payload, loading: false, };
    },
    setEthNetwork(state, action: PayloadAction<string>): WalletState {
      return { ...state, ethNetwork: action.payload };
    },
  }
});

const init = (): AppThunk => async (dispatch, getState) => {
  try {
    const web3Modal = getWeb3Modal();
    if (web3Modal.cachedProvider) {
      dispatch(connectWallet({}));
    }
    else {
      dispatch(service.actions.setAccount(null));
    }
  }
  catch (e) {
    console.error(e);
  }
}

const disconnectWallet = (): AppThunk => async (dispatch, getState) => {
  try {
    const web3Modal = getWeb3Modal();
    web3Modal.clearCachedProvider();
    dispatch(service.actions.setAccount(null));
    walletCache = null;
    trackWalletDisconnected();
  }
  catch (e) {
    console.error(e);
  }
}



let walletCache: Wallet | null;

export const getWallet = () => walletCache;

export const getWeb3Modal = () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        infuraId: process.env.GATSBY_INFURA_ID // required
      }
    }
  };

  return new Web3Modal({
    theme: 'dark',
    network: process.env.GATSBY_ETH_NETWORK,
    cacheProvider: true,
    providerOptions,
  });
}

export interface Wallet {
  instance: any;
  provider: ethers.providers.Web3Provider;
}

export interface ConnectWalletArgs {
  onConnected?: (wallet: Wallet) => void;
}

const listenForEvents = (): AppThunk => async (dispatch, getState) => {
  if (!walletCache) {
    return;
  }

  // Subscribe to accounts change
  walletCache.instance.on("accountsChanged", (accounts: string[]) => {
    console.log('accountsChanged', accounts);
    if (accounts.length > 0) {
      dispatch(service.actions.setAccount(accounts[ 0 ]));
    }
  });

  // Subscribe to chainId change
  walletCache.instance.on("chainChanged", async (chainId: number) => {
    console.log('chainChanged', chainId);
    dispatch(service.actions.setEthNetwork(chainId.toString()));
  });

  // Subscribe to provider connection
  walletCache.instance.on("connect", (info: { chainId: number }) => {
    // dispatch(service.actions.setConnected(true));
    console.log('connect', info);
  });

  // Subscribe to provider disconnection
  walletCache.instance.on("disconnect", (error: { code: number; message: string }) => {
    // dispatch(service.actions.setConnected(false));
    console.log('disconnect', error);
  });
}

export const connectWallet = ({ onConnected }: ConnectWalletArgs): AppThunk => async (dispatch, getState) => {
  try {
    if (walletCache) {
      if (onConnected) {
        onConnected(walletCache);
      }
      trackWalletConnected();
      return;
    }

    const web3Modal = getWeb3Modal();

    const instance = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(instance);

    walletCache = {
      instance,
      provider,
    }

    const signer = walletCache.provider.getSigner();
    const address = await signer.getAddress();
    dispatch(service.actions.setAccount(address));

    if (onConnected) {
      onConnected(walletCache);
    }
    trackWalletConnected();
    dispatch(listenForEvents());

  }
  catch (e) {
    console.error(e);
  }
}


export const actions = {
  init,
  connectWallet,
  disconnectWallet,
}

export default service.reducer;