import { logEvent } from "firebase/analytics";
import { getFirebase } from "./firebase"


const trackEvent = (event: string, params?: { [ key: string ]: any }) => {
  const firebase = getFirebase();

  logEvent(firebase.analytics, event, params);
}


export const trackWhitelistSubmitted = () => {
  trackEvent('whitelist_submitted');
}

export const trackMintPendingConfirm = (count: number) => {
  trackEvent('mint_pending_confirm', { count });
}

export const trackWhitelistMintPendingConfirm = (count: number) => {
  trackEvent('mint_pending_confirm_whitelist', { count });
}

export const trackMintToken = (count: number) => {
  trackEvent('mint_token', { count });
}

export const trackMintWhitelistToken = (count: number) => {
  trackEvent('mint_token_whitelist', { count });
}


export const trackWalletConnected = () => {
  trackEvent('wallet_connected');
}

export const trackWalletDisconnected = () => {
  trackEvent('wallet_disconnected');
}


export const trackPageView = () => {
  trackEvent('page_view')
}