import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from "ethers";
import { AppThunk } from '../store';
import { NftMetadata } from '../types/types';
import { getContract } from '../web3/contract';
import { getWallet } from './wallet';

interface NftState {
  myCollectionLoading: boolean;
  myCollection: number[];
  collectionLoading: boolean;
  collection: number[];
  metadata: {
    [ id: number ]: NftMetadata;
  }
}

const initialState: NftState = {
  metadata: {},
  myCollectionLoading: false,
  myCollection: [],

  collectionLoading: false,
  collection: [],
}



const service = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setMyCollectionLoading(state, action: PayloadAction<boolean>): NftState {
      return { ...state, myCollectionLoading: action.payload };
    },
    setCollectionLoading(state, action: PayloadAction<boolean>): NftState {
      return { ...state, collectionLoading: action.payload };
    },
    setMyCollection(state, action: PayloadAction<number[]>): NftState {
      return { ...state, myCollection: [ ...action.payload ] };
    },
    setMetadata(state, action: PayloadAction<{ [ id: number ]: NftMetadata }>): NftState {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload,
        }
      };
    },

  }
});


const loadMyCollection = (): AppThunk => async (dispatch, getState) => {
  try {
    const wallet = getWallet();
    if (!wallet) {
      return;
    }

    // if (getState().nft.myCollection.length > 0) {
    //   return;
    // }


    dispatch(service.actions.setMyCollectionLoading(true));

    const signer = wallet.provider.getSigner();
    const address = await signer.getAddress();

    const contract = await getContract();
    const result = await contract.getTokensByOwner(address);

    const list = result.map((item: any) => item.toNumber());
    const metadatas = await loadMetadatas(list);
    dispatch(service.actions.setMyCollection(list));
    dispatch(service.actions.setMetadata(metadatas));
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setMyCollectionLoading(false));
  }
}


const loadMetadatas = async (ids: number[]) => {
  let metadata: { [ id: number ]: NftMetadata } = {};
  try {
    const url = `${process.env.GATSBY_API_BASE}/metadata/get-list`;
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ids: ids.map(i => i.toString()),
      })
    });
    metadata = await resp.json();
  }
  catch (e) {
    console.error(e);
  }

  return metadata;
}



export const actions = {
  loadMyCollection,
}

export default service.reducer;