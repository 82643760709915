import { combineReducers, configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';


import stats, { actions as statsActions } from './services/stats';
import mint from './services/mint';
import nft from './services/nft';
import whitelist from './services/whitelist';
import wallet, { actions as walletActions } from './services/wallet';

const rootReducer = combineReducers({
  stats,
  mint,
  wallet,
  nft,
  whitelist,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk<T = void> = ThunkAction<T, RootState, null, Action<string>>;

const store = configureStore({
  reducer: rootReducer
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./store', () => {
    store.replaceReducer(rootReducer);
  });
}

if (typeof window !== 'undefined') {
  if (process.env.GATSBY_SALE_MODE !== 'coming_soon') {
    store.dispatch(statsActions.loadStats() as any);
  }
  store.dispatch(walletActions.init() as any);
}

export type AppDispatch = typeof store.dispatch;
export default store; 