import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from "ethers";
import { AppThunk } from '../store';
import { getContract } from '../web3/contract';

interface StatsState {
  loading: boolean;
  totalSupply: number;
  circulatinSupply: number;
  startPrice: string;
  currentPrice: string;
  priceIncrement: string;
  currentRange: number;
  maxRanges: number;
  tokensPerRange: number;
  mintingOpen: boolean;
}

const initialState: StatsState = {
  loading: false,
  totalSupply: 9999,
  circulatinSupply: 0,
  startPrice: '0.07',
  currentPrice: '0',
  priceIncrement: '0.005',
  currentRange: 0,
  maxRanges: 10,
  tokensPerRange: 1000,
  mintingOpen: false,
}



const service = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): StatsState {
      return { ...state, loading: action.payload };
    },
    setData(state, action: PayloadAction<StatsState>): StatsState {
      return { ...action.payload };
    },
  }
});


const loadStats = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(service.actions.setLoading(true));

    const contract = await getContract();
    console.log('contract', contract);
    
    const result = await contract.getStats();

    console.log(result);

    const totalSupply = result[ 0 ].toNumber();
    const circulatinSupply = result[ 1 ].toNumber();
    const startPrice = ethers.utils.formatEther(result[ 2 ]);
    const currentPrice = ethers.utils.formatEther(result[ 3 ]);
    const priceIncrement = ethers.utils.formatEther(result[ 4 ]);
    const currentRange = result[ 5 ].toNumber();
    const maxRanges = result[ 6 ].toNumber();
    const tokensPerRange = result[ 7 ].toNumber();
    const mintingOpen = result[ 8 ];

    console.log({
      totalSupply,
      circulatinSupply,
      startPrice,
      currentPrice,
      priceIncrement,
      currentRange,
      maxRanges,
      tokensPerRange,
      mintingOpen,
      loading: false,
    });

    dispatch(service.actions.setData({
      totalSupply,
      circulatinSupply,
      startPrice,
      currentPrice,
      priceIncrement,
      currentRange,
      maxRanges,
      tokensPerRange,
      mintingOpen,
      loading: false,
    }));


  }
  catch (e) {
    console.error(e);
    dispatch(service.actions.setLoading(false));
  }
}



export const actions = {
  loadStats,
}

export default service.reducer;